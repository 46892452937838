<template>
  <div style="width: 100vw;height: 100vh;background-color: #F3F5F9;">
            <!-- <div class="rightView"></div> -->
            <img class="loginBackImg" src="../../assets/am-login-bg.png">
    <div
        style="box-sizing: border-box;padding-left: 60px;padding-right:60px;margin-left: 33vw;width: 33vw;height: 100%;display: flex;flex-direction: column;justify-content: center;background-color: white;  position: absolute;">


      <!-- <div class="login-title-big">Remote Exam</div> -->
      <img class="loginLogo" src="../../assets/am-logo.png">

      

      <div class="login-title" style="margin-top: 30px">用户名</div>
      <input class="login-input" v-model="form.account_name"/>
      <div class="login-title" style="margin-top: 30px">密码</div>
      <input class="login-input" v-model="form.account_pwd"/>

      <div class="login-title" style="margin-top: 30px">身份</div>

      <div class="login-input1">
          <el-select type="text" :popper-append-to-body="false" class="loginInput" placeholder="Identity" v-model="form.type" style="width:100%">
              <el-option value="学生" label="Student"></el-option>
              <el-option value="讲师" label="Lecturer"></el-option>
              <el-option value="助教" label="Teaching Assistant"></el-option>
          </el-select>
      </div>

      <div style="margin-top: 40px;position: relative">
        <div style="position: absolute;right: 0">
          <img @click="submit" src="../../assets/login-arrow.png" style="cursor: pointer;width: 3vw;height: auto">
        </div>

      </div>
      
      <!--            <div class="loginContentContainer">-->
      <!--                <div class="content">-->
      <!--                    <div class="left">-->
      <!--                        <div class="loginTitle">AM Arena</div>-->
      <!--                        <div class="loginInputView">-->
      <!--                            <el-input type="text" class="loginInput" placeholder="Email" v-model="form.account_name"></el-input>-->
      <!--                        </div>-->
      <!--                        <div class="loginInputView">-->
      <!--                            <el-input type="password" class="loginInput" placeholder="Password" v-model="form.account_pwd"></el-input>-->
      <!--                        </div>-->
      <!--                        <div class="loginInputView">-->
      <!--                            <el-select type="text" class="loginInput" placeholder="Identity" v-model="form.type" style="width:100%">-->
      <!--                                <el-option value="学生" label="Student"></el-option>-->
      <!--&lt;!&ndash;                                <el-option value="讲师" label="Lecturer"></el-option>&ndash;&gt;-->
      <!--&lt;!&ndash;                                <el-option value="助教" label="Teaching Assistant"></el-option>&ndash;&gt;-->
      <!--                            </el-select>-->
      <!--                        </div>-->
      <!--                        <div class="loginBtn" @click="submit">Login</div>-->
      <!--                    </div>-->
      <!--                    <div class="right">-->
      <!--                        <img src="../../assets/logo.png" class="logo">-->
      <!--                    </div>-->
      <!--                </div>-->
      <!--            </div>-->

    </div>
  </div>
</template>

<script>
import {loginByEducator, loginByStudent, loginByEmployee} from '../../api/index'
import {
  setUserId,
  setUserName,
  setUserType,
  getProjectCode,
  setApplicationExtraEvents,
  setAppId,
    setAccountName,
  setApplicationEvents, setAvatarUrl,logout
} from '../../utils/store'
import '../../assets/common/font.css'
export default {
  name: 'Login',
  data() {
    return {
      form: {
        account_name: '',
        account_pwd: '',
        type: '学生'
      }
    }
  },
  mounted() {
    //先清除一下数据
    logout()
  },
  methods: {
    submit() {
      if (!this.form.account_name) {
        this.$message.error('Please input email')
        return
      }

      if (!this.form.account_pwd) {
        this.$message.error('Please input password')
        return
      }

      if (this.form.type === '讲师') {
        loginByEducator(this.form.account_name, this.form.account_pwd).then(res => {
          if (res.data.code == 0) {
            this.$message({
              message: 'login success',
              type: 'success'
            })
            setUserId(res.data.data._id.$id)
            setUserName(res.data.data.educator_first_name + res.data.data.educator_last_name)
            setUserType('educator')
            this.$router.push('/teacherlist')
          } else {
            this.$message.warning('login fail')
          }
        })
      } else if (this.form.type === '学生') {
        loginByStudent(getProjectCode(), this.form.account_name, this.form.account_pwd).then(res => {
          if (res.data.code == 0) {
            this.$message({
              message: 'login success',
              type: 'success'
            })
            var result = res.data.data
            var application_extra_events= result.application_extra_events
            setApplicationExtraEvents(application_extra_events)
            var application_events= result.application_events
            setApplicationEvents(application_events)
            var app_id = result._id.$id
            setAppId(app_id)
            var account_name = result['student_info']['account_name']
            setAccountName(account_name)
            var avatar_url = result['student_info']['avatar_url']
            setAvatarUrl(avatar_url)
            setUserId(res.data.data.student_id)
            setUserType('student')
            var student_name = res.data.data.student_info.student_lastName + res.data.data.student_info.student_givenName
            setUserName(student_name)
            this.$router.push('/home')
          } else {
            this.$message.warning('Login failed: ' + res.data.msg)
          }
        })
      } else if (this.form.type === '助教') {
        loginByEmployee(this.form.account_name, this.form.account_pwd).then(res => {
          if (res.data.code == 0) {
            this.$message({
              message: 'login success',
              type: 'success'
            })
            setUserId(res.data.data._id.$id)
            setUserType('employee')
            setUserName(res.data.data.user_lastName + res.data.data.user_givenName)
            this.$router.push('/employeelist')
          } else {
            this.$message.warning('login fail')
          }
        })
      }

    }
  }
}
</script>

<style lang="less">

.loginBackImg {
  // 1650 × 2028
  width: 100%;
  height: 100%;
  position: absolute;
  // z-index: 2;
  top: 0;
  align-items: center;
  right: 0;
  // left: 0;
}
.rightView {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 30%;
  background-color: #4468EC;
}
.loginLogo {
  width: 30%;
  // height: 30%;
  position: absolute;
  // z-index: -1;
  top: 70px;
  align-items: center;
  left: 35%;
}

.login-title-big {
  position: absolute;
  color: #4468EC;
  // background-color: #E74646;
  font-size: 43px;
  font-weight: 600;
  font-family: "Montserrat-SemiBold";
  text-align: center;
  top: 100px;
  left: 16vw;
  width: 33vw;
}
.login-title {
  color: #273161;
  font-size: 0.728vw;
  font-weight: 400;
  font-family: "PingFang SC";
}
.login-input {
  margin-top: 2px;
  box-sizing: border-box;
  border: 0px solid transparent;
  border-bottom-width: 2px;
  border-bottom-color:#273161;
  color: #273161;
  font-size: 1.1vw;
  width: 100%;
  height: 3vw;
  background-color: transparent;
  outline: none;
  border-radius: 0px;
  padding: 0 40px;
}
.login-input1 {
  margin-top: 2px;
  box-sizing: border-box;
  border: 0px solid transparent;
  border-bottom-width: 2px;
  border-bottom-color:#333333;
  color: #333333;
  font-size: 1.1vw;
  width: 100%;
  height: 3vw;
  background-color: transparent;
  outline: none;
  border-radius: 0px;
  padding: 0 0 0 25px;
}
.loginInput {
  background-color: transparent;
}

.el-select-dropdown__item.selected {
  color: rgb(243, 152, 0);
  font-weight: 700;
}
.el-select{
    width: 100%;
    color: #333333;
    display: inline-block;
    input{
        font-size: 1.1vw;
        border: none;
        background: none;
        text-align: left;
        color: #333333;
        // font-weight: bold;
    }
    .el-input__suffix{
        i{
            color: transparent;
            margin-left: 0.06rem;
            font-weight: bold;
        }
    }
    /* WebKit browsers */
    ::-webkit-input-placeholder {
        color: transparent;
        font-weight: bold;
    }
}


</style>
